import React, { useState } from "react";
import "twin.macro";

import "./App.css";
import { Button } from "./button/Button";
import { Input } from "./input/Input";
import { RenteSchema } from "./rente-schema/RenteSchema";
import { RentePerJaar } from "./rente-per-jaar/RentePerJaar";
import { percent, valuta } from "./util";

function App() {
  const [hypotheekBedrag, setHypotheekBedrag] = useState(200000);
  const [jaarRentePercentages, setJaarRentePercentages] = useState([
    { rente: 2, ltv: 1 },
    { rente: 1.5, ltv: 0.9 },
    { rente: 1, ltv: 0.8 },
  ]);
  const [looptijd, setLooptijd] = useState(30);
  const [startDatum, setStartDatum] = useState("2020-11-01");
  const [laagInkomen, setLaagInkomen] = useState(true);
  const [ewfPercentage] = useState(0.006);
  const [totaalNettoLast, setTotaalNettoLast] = useState(0);

  const maandRente = jaarRentePercentages[0].rente / 100 / 12;
  const eigenwoningforfait = ewfPercentage * hypotheekBedrag;

  return (
    <div tw="container mx-auto my-8">
      <h1 tw="text-4xl font-bold my-3">Betaalschema annuïteitenhypotheek berekenen</h1>
      <p tw="mb-4">
        Alle berekeningen vinden plaats in de browser, dus er wordt niets naar de server gestuurd.
        <br />
        Fouten zijn voor eigen risico.
      </p>
      <table tw="border-collapse">
        <tbody>
          <tr>
            <th css={{ verticalAlign: "top" }}>
              Rente per jaar
              <div tw="font-normal text-sm mt-5">
                Vul in:{" "}
                <Button
                  tw="mx-2"
                  onClick={() =>
                    setJaarRentePercentages([
                      { rente: 1.5, ltv: 1 },
                      { rente: 1.34, ltv: 0.85 },
                      { rente: 1.14, ltv: 0.65 },
                    ])
                  }
                >
                  ABN Amro
                </Button>
                <Button
                  tw="mx-2"
                  onClick={() =>
                    setJaarRentePercentages([
                      { rente: 1.6, ltv: 1 },
                      { rente: 1.44, ltv: 0.85 },
                      { rente: 1.24, ltv: 0.65 },
                    ])
                  }
                >
                  ABN Amro woning
                </Button>
                <Button
                  tw="mx-2"
                  onClick={() =>
                    setJaarRentePercentages([
                      { rente: 1.55, ltv: 1 },
                      { rente: 1.35, ltv: 0.9 },
                      { rente: 1.15, ltv: 0.675 },
                    ])
                  }
                >
                  Rabobank
                </Button>
                <Button
                  tw="mx-2"
                  onClick={() =>
                    setJaarRentePercentages([
                      { rente: 1.57, ltv: 1 },
                      { rente: 1.42, ltv: 0.9 },
                      { rente: 1.32, ltv: 0.8 },
                      { rente: 1.17, ltv: 0.7 },
                    ])
                  }
                >
                  Obvion
                </Button>
                <Button
                  tw="mx-2"
                  onClick={() =>
                    setJaarRentePercentages([
                      { rente: 1.59, ltv: 1 },
                      { rente: 1.54, ltv: 0.9 },
                      { rente: 1.44, ltv: 0.8 },
                      { rente: 1.39, ltv: 0.7 },
                      { rente: 1.34, ltv: 0.6 },
                      { rente: 1.29, ltv: 0.5 },
                    ])
                  }
                >
                  NN
                </Button>
                <div tw="italic mt-2">10 Jaar vast incl. huisbank- en duurzaamheidskorting</div>
              </div>
            </th>
            <td>
              <RentePerJaar onChange={setJaarRentePercentages} rentes={jaarRentePercentages} />
            </td>
          </tr>
          <tr>
            <th>Hypotheekbedrag</th>
            <td>
              <Input type="number" onChange={e => setHypotheekBedrag(e.target.value)} value={hypotheekBedrag} />
            </td>
          </tr>
          <tr>
            <th>Looptijd (jaar)</th>
            <td>
              <Input type="number" onChange={e => setLooptijd(e.target.value)} value={looptijd} />
            </td>
          </tr>
          <tr>
            <th>Startdatum</th>
            <td>
              <Input type="date" onChange={e => setStartDatum(e.target.value)} value={startDatum} />
            </td>
          </tr>
          <tr>
            <th>Eigenwoningforfait percentage (in 2021 0,6% tot 1,1 miljoen)</th>
            <td>
              {percent(ewfPercentage)} ({valuta(eigenwoningforfait)} p.j.)
            </td>
          </tr>
          <tr>
            <th>Hoger jaarinkomen dan {valuta(68507)}</th>
            <td>
              <label tw="mr-3">
                <input defaultChecked={laagInkomen} onChange={() => setLaagInkomen(true)} name="inkomen" type="radio" />{" "}
                Nee
              </label>
              <label>
                <input
                  defaultChecked={!laagInkomen}
                  name="inkomen"
                  onChange={() => setLaagInkomen(false)}
                  type="radio"
                />{" "}
                Ja
              </label>
              {laagInkomen}
            </td>
          </tr>
          {/* <tr>
            <th>Bruto maandbedrag</th>
            <td>{valuta(maandbedrag)}</td>
          </tr> */}
          <tr>
            <th>Totaal netto kosten rente</th>
            <td>{valuta(totaalNettoLast - hypotheekBedrag)}</td>
          </tr>
        </tbody>
      </table>

      <h2 tw="text-3xl font-bold my-4">Aflossingsschema</h2>
      <RenteSchema
        eigenwoningforfait={eigenwoningforfait}
        hypotheekBedrag={hypotheekBedrag}
        jaarRentePercentages={jaarRentePercentages}
        laagInkomen={laagInkomen}
        looptijd={looptijd}
        maandRente={maandRente}
        startDatum={new Date(startDatum)}
        onUpdateTotaalNettoLast={setTotaalNettoLast}
      />
    </div>
  );
}

export default App;
