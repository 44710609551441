import React, { Fragment } from "react";
import "twin.macro";

import { Input } from "../input/Input";

export const RentePerJaar = ({ onChange, rentes }) => {
  const setVal = (index, key, value) => {
    const res = [...rentes];
    res[index][key] = value;
    onChange(res);
  };
  return (
    <div tw="grid grid-cols-2 gap-x-8 gap-y-2">
      <div tw="font-bold">Loan-to-value</div>
      <div tw="font-bold">Rente</div>
      {rentes.map((d, i) => (
        <Fragment key={i}>
          <div>
            <Input
              disabled={!i}
              type="number"
              onChange={e => setVal(i, "ltv", +e.target.value / 100)}
              value={d.ltv * 100}
              tw="mr-1 w-24"
            />
            %
          </div>
          <div>
            <Input type="number" onChange={e => setVal(i, "rente", +e.target.value)} value={d.rente} tw="mr-1 w-24" />%
          </div>
        </Fragment>
      ))}
    </div>
  );
};
