export const percent = new Intl.NumberFormat("nl-NL", {
  style: "percent",
  maximumFractionDigits: 2,
}).format;

export const valuta = new Intl.NumberFormat("nl-NL", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format;

// <= € 68.507
export const belasting = (year, smallIncome) =>
  smallIncome
    ? {
        2020: 0.3735,
        2021: 0.371,
      }[year] || 0.371
    : 0.495;

// Bereken rentepercentage behorende bij loan-to-value
export const rente = (rentes, ltv) => {
  return rentes.reduce((acc, d) => (ltv <= d.ltv ? Math.min(d.rente, acc) : acc), 100);
};
