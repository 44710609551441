import { addMonths, format } from "date-fns";
import { nl } from "date-fns/locale";
import React, { useEffect } from "react";
import "twin.macro";

import { belasting, rente, percent, valuta } from "../util";

export const RenteSchema = ({
  eigenwoningforfait,
  hypotheekBedrag,
  jaarRentePercentages,
  laagInkomen,
  looptijd,
  startDatum,
  onUpdateTotaalNettoLast,
}) => {
  // Calculate general values
  const maanden = Array(looptijd * 12).fill();
  const eigenwoningforfaitPerMaand = eigenwoningforfait / 12;

  // Calculate all table values
  const values = maanden.reduce((acc, _, i) => {
    const prev = acc.length && acc[acc.length - 1];
    let openstaand = prev ? prev.openstaandNieuw : hypotheekBedrag;

    const ltv = openstaand / hypotheekBedrag;
    const jaarRentePerc = rente(jaarRentePercentages, ltv);
    const maandRentePerc = jaarRentePerc / 100 / 12;

    const date = addMonths(startDatum, i);
    const year = format(date, "yyyy");

    // Update maandbedrag when there is a new maandrente
    let brutoMaandlast;
    if (prev && prev.jaarRentePerc === jaarRentePerc) {
      brutoMaandlast = prev.brutoMaandlast;
    } else {
      const looptijdMaanden = looptijd * 12 - i;
      brutoMaandlast = (maandRentePerc / (1 - Math.pow(1 + maandRentePerc, -looptijdMaanden))) * openstaand;
    }

    const renteBedrag = openstaand * maandRentePerc;
    const aflossing = brutoMaandlast - renteBedrag;

    const ibTarief = belasting(year, laagInkomen);
    const ibTariefAftrekbaar = Math.min(ibTarief, { 2020: 0.46, 2021: 0.43, 2022: 0.4 }[year] || 0.371);

    const ewfAftrekbaar = +year === 2020 ? 0.9333 : +year > 2048 ? 0 : 0.9333 + (2020 - +year) * 0.0333;
    const ewf =
      renteBedrag > eigenwoningforfaitPerMaand
        ? eigenwoningforfaitPerMaand * ibTariefAftrekbaar
        : eigenwoningforfaitPerMaand * ibTariefAftrekbaar * (1 - ewfAftrekbaar);

    const hra = ibTariefAftrekbaar * renteBedrag;
    const nettoMaandlast = brutoMaandlast + ewf - hra;

    acc.push({
      aflossing,
      brutoMaandlast,
      date,
      ewf,
      hra,
      jaarRentePerc,
      ltv,
      nettoMaandlast,
      openstaand,
      openstaandNieuw: openstaand - aflossing,
      renteBedrag,
    });

    return acc;
  }, []);

  useEffect(() => onUpdateTotaalNettoLast(values.reduce((acc, { nettoMaandlast }) => acc + nettoMaandlast, 0)));

  return (
    <table>
      <thead>
        <tr>
          <th></th>
          <th tw="border-r-2">Maand</th>
          <th>Openstaand</th>
          <th>Loan to value</th>
          <th>Jaarrente</th>
          <th>Bruto maandlast</th>
          <th>Aflossing</th>
          <th tw="border-r-2">Rente</th>
          <th>Eigenwoningforfait</th>
          <th>Hypotheekrenteaftrek</th>
          <th>Netto maandlast</th>
        </tr>
      </thead>
      <tbody>
        {values.map(
          (
            { aflossing, brutoMaandlast, date, ewf, hra, jaarRentePerc, openstaand, ltv, nettoMaandlast, renteBedrag },
            i
          ) => {
            return openstaand > 0 ? (
              <tr key={i}>
                <th>{i + 1}</th>
                <td tw="border-r-2">{format(date, "MMM yyyy", { locale: nl })}</td>
                <td>{valuta(openstaand)}</td>
                <td>{percent(ltv)}</td>
                <td>{percent(jaarRentePerc / 100)}</td>
                <td>{valuta(brutoMaandlast)}</td>
                <td>{valuta(aflossing)}</td>
                <td tw="border-r-2">{valuta(renteBedrag)}</td>
                <td>{valuta(ewf)}</td>
                <td>{valuta(hra)}</td>
                <td>{valuta(nettoMaandlast)}</td>
              </tr>
            ) : null;
          }
        )}
      </tbody>
    </table>
  );
};
